<template>
  <div style="border-radius: 5px">
    <div class="detal detal1">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="商品发票" name="orderInvoiceList"></el-tab-pane>
        <el-tab-pane label="服务发票" name="serviceInvoiceList"></el-tab-pane>
        <el-tab-pane label="运费发票" name="platFormFreightList"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="stepbox row no-gutters align-items-center">
      <h3 class="col-auto mr-3">发票索取</h3>
      <div class="col-auto">
        <p>1.开票订单选择</p>
        <div class="threeth"></div>
      </div>
      <div class="activediv col-auto">
        <div class="threeths"></div>
        <p>2.确定发票信息和地址</p>
        <div class="threeth"></div>
      </div>
    </div>
    <div class="detal">
      <div class="seletbox">
        <p>温馨提示：</p>
        <p>1. 发票订单开具（单个订单不可拆分开票）。</p>
        <p>2. 单张发票金额限额100万，索取金额超过100万将拆分成多张发票开具。</p>
        <div class="conditions">
          <span>订单完成时间：</span>
          <el-date-picker v-model="value1" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
            size="medium" value-format="timestamp" style="width: 220px"></el-date-picker>
          <div style="display: inline; margin-left: 8px" v-if="activeName === 'orderInvoiceList'">
            <span>商品名称：</span>
            <el-input v-model="paramsData.goods_name" style="width: 150px" size="medium" placeholder="请输入商品名称"
              clearable></el-input>
          </div>
          <div style="display: inline; margin-left: 8px"
            v-if="activeName === 'orderInvoiceList' || activeName === 'platFormFreightList'">
            <span>站点名称：</span>
            <el-select style="width: 190px" size="medium" v-model="paramsData.shop_id" placeholder="请选择站点" clearable>
              <el-option label="全部" value=""></el-option>
              <el-option v-for="item in shop_list" :key="item.shop_id" :label="item.shop_name"
                :value="item.shop_id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="conditions" style="margin-left: 16px">
          <el-button @click="getList" type="primary" size="small">搜索</el-button>
        </div>
        <div style="display: block; position: relative; top: 20px" class="hover">
          <el-button style="top: 50%; transform: translateY(-50%)" @click="showPartFn(1)" type="primary"
            size="small">开票信息管理</el-button><el-button style="top: 50%; transform: translateY(-50%); left: 100px"
            @click="showPartFn(2)" type="primary" size="small">查看开票记录</el-button>
        </div>
      </div>
      <div class="seletboxp">
        <p style="position: relative">可开票列表&nbsp;&nbsp;&nbsp;&nbsp;</p>
      </div>
      <div class="databox">
        <div>
          <div style="width: 40%">
            <p>已选开票金额（元）</p>
            <p class="yikai" v-if="allPage || allPageService || allPagePlatForm">
              {{ toDoPrice0 }}
            </p>
            <p class="yikai" v-else>{{ choosePrice1 }}</p>
          </div>
          <div style="width: 40%">
            <p>待开票总金额（元）</p>
            <p class="daikai">{{ toDoPrice0 }}</p>
          </div>
          <div class="goright">
            <el-button @click="requestInvoice" size="small" type="primary">索取发票</el-button>
            <br />
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">
                <p>1.代开票金额小于¥200。</p>
                <p>2.已选开票金额小于¥200。</p>
              </div>
              <el-button class="noueb" size="small">无法开票原因？</el-button>
            </el-tooltip>
          </div>
        </div>
      </div>

      <!-- <h3>开票订单信息</h3> -->
      <div class="h3"></div>
      <div class="databox databox2">
        <!-- checkbox勾选框-->
        <div v-show="activeName === 'orderInvoiceList'" style="padding: 10px 0 0 20px">
          <el-checkbox v-model="thisPage" @change="isChangeThisPage">
            全选本页
          </el-checkbox>
          <el-checkbox v-model="allPage" @change="isChangeAllPage">
            全选所有页
          </el-checkbox>
        </div>
        <div v-show="activeName === 'serviceInvoiceList'" style="padding: 10px 0 0 20px">
          <el-checkbox v-model="thisPageService" @change="isChangeThisPageService">
            全选本页
          </el-checkbox>
          <el-checkbox v-model="allPageService" @change="isChangeAllPageService">
            全选所有页
          </el-checkbox>
        </div>
        <div v-show="activeName === 'platFormFreightList'" style="padding: 10px 0 0 20px">
          <el-checkbox v-model="currentPagePlatForm" @change="isChangeThisPagePlatForm">
            全选本页
          </el-checkbox>
          <el-checkbox v-model="allPagePlatForm" @change="isChangeAllPagePlatForm">
            全选所有页
          </el-checkbox>
        </div>
        <!--  表格数据-->
        <div v-show="activeName === 'orderInvoiceList'">
          <el-table ref="orderInvoiceList" :row-key="row => row.order_id" :data="orderInvoiceList"
            @selection-change="orderinvoiceListChange" :header-cell-style="{
        textAlign: 'center',
        backgroundColor: 'rgba(230, 236, 247, 1)',
      }" highlight-current-row border>
            <el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
            <el-table-column prop="trade_sn" label="关联总订单号"></el-table-column>
            <el-table-column prop="sn" label="订单号"></el-table-column>
            <el-table-column prop="complete_time" label="完成时间" width="160">
              <template slot-scope="scope">
                {{ scope.row.complete_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="商品名称 x 购买数量 / 商品单价">

              <template slot-scope="scope">
                <div v-for="(item, index) in scope.row.items_json" :key="index">
                  {{ item.name }} x {{ item.num }} 单价:
                  {{ item.original_price }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="80" prop="order_price" label="总价">

              <template slot-scope="scope">
                {{ scope.row.order_price | unitPrice }}</template>
            </el-table-column>
            <el-table-column prop="order_price" label="可开具金额">

              <template slot-scope="scope">
                {{ scope.row.order_price - scope.row.order_refund_price | unitPrice }}</template>
            </el-table-column>
          </el-table>
        </div>
        <div v-show="activeName === 'serviceInvoiceList'">
          <el-table ref="serviceInvoiceList" :row-key="row => row.id" :data="serviceInvoiceList"
            @selection-change="serviceInvoiceListChange" :header-cell-style="{
        textAlign: 'center',
        backgroundColor: 'rgba(230, 236, 247, 1)',
      }" highlight-current-row border style="width: 100%">
            <el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
            <el-table-column prop="sn" label="订单号"></el-table-column>
            <el-table-column prop="pay_time" label="完成时间" width="160">

              <template slot-scope="scope">
                {{ scope.row.pay_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column prop="service_name" label="服务名称"></el-table-column>
            <el-table-column prop="price" label="可开具金额">

              <template slot-scope="scope">
                {{ scope.row.price | unitPrice }}</template>
            </el-table-column>
          </el-table>
        </div>
        <div v-show="activeName === 'platFormFreightList'">
          <el-table ref="platFormInvoiceList" :row-key="row => row.id" :data="platFormInvoiceList"
            @selection-change="platFormInvoiceListChange" :header-cell-style="{
        textAlign: 'center',
        backgroundColor: 'rgba(230, 236, 247, 1)',
      }" highlight-current-row border style="width: 100%">
            <el-table-column :reserve-selection="true" type="selection" width="55" />
            <el-table-column prop="trade_sn" label="关联总订单号" />
            <el-table-column prop="create_time" label="完成时间" width="160">

              <template slot-scope="scope">
                {{ scope.row.complete_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column prop="platform_freight_price" label="运费金额">

              <template slot-scope="scope">
                {{ scope.row.platform_freight_price | unitPrice('¥') }}
              </template>
            </el-table-column>
            <el-table-column prop="platform_freight_price" label="可开具金额">

              <template slot-scope="scope">
                {{ scope.row.platform_freight_price | unitPrice('¥') }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="text-align: center">
          <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
            @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="MixinPageSizes"
            :layout="MixinTableLayout" background :page-size="tableData.page_size"
            :total="tableData.data_total"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API_goods from "@/api/goods";
import * as API_goodsCategory from "@/api/goodsCategory";

export default {
  name: "goodsAudit",
  data () {
    return {
      activeName: "orderInvoiceList",
      value1: [],
      tableData: {},
      // 站点列表
      shop_list: [],
      paramsData: {
        page_no: 1,
        page_size: 20,
        startTime: "",
        endTime: "",
        seller_name: "",
        shop_id: "",
        invoice_check: 1,
        enterprise_invoice: 0
      },
      // 订单发票
      orderInvoiceList: [],
      selectOrderinvoice: [],
      thisPage: false,
      allPage: false,
      idsList: [],
      // 服务发票
      serviceInvoiceList: [],
      selectServiceInvoice: [],
      thisPageService: false,
      allPageService: false,
      idsListService: [],
      // 平台发票
      platFormInvoiceList: [],
      selectPlatFormInvoiceList: [],
      currentPagePlatForm: false,
      allPagePlatForm: false,
      idsListPlatForm: [],

      toDoPrice0: 0, //待开票总金额
      choosePrice1: 0, //已选开票金额
    };
  },
  mounted () {
    API_goodsCategory.getStoreList().then((res) => {
      this.shop_list = res;
    });
    const type = this.$route.query.type
    if (!type || type === '1') {
      this.activeName = 'orderInvoiceList'
      this.getWaitReceiptOrderList();
      // this.GET_ToDoPrice0();
      // this.GET_ChoosePrice1();
    } else if (type === '2') {
      this.activeName = 'serviceInvoiceList'
      this.getShopReceipt();
    } else if (type === '3') {
      this.activeName = 'platFormFreightList'
      this.getPlatFormFreight();
    }
  },
  watch: {
    activeName: function (val) {
      //监听切换状态
      this.paramsData.seller_name = "";
      this.paramsData.shop_id = "";
      this.paramsData.startTime = "";
      this.paramsData.endTime = "";
      this.allPage = false;
      this.thisPage = false;
      this.value1 = [];
      this.handleClick();
    },
  },
  methods: {
    handleClick () {
      if (this.activeName === "orderInvoiceList") {
        this.paramsData.page_no = 1;
        this.getWaitReceiptOrderList();
        this.requestInvoiceData = JSON.parse(
          JSON.stringify(this.selectOrderinvoice)
        );
      }
      if (this.activeName === "serviceInvoiceList") {
        this.paramsData.page_no = 1;
        this.getShopReceipt();
        this.requestInvoiceData = JSON.parse(
          JSON.stringify(this.selectServiceInvoice)
        );
      }
      if (this.activeName === "platFormFreightList") {
        this.paramsData.page_no = 1;
        this.getPlatFormFreight();
        this.requestInvoiceData = JSON.parse(
          JSON.stringify(this.selectServiceInvoice)
        );
      }
    },
    // 订单发票
    getWaitReceiptOrderList () {
      API_goods.getWaitReceiptOrderList(this.paramsData).then((res) => {
        this.orderInvoiceList = res.data;
        this.tableData = res;
        this.orderInvoiceList.map((item, index) => {
          this.orderInvoiceList[index].items_json = JSON.parse(
            this.orderInvoiceList[index].items_json
          );
        });
      });
      this.GET_ToDoPrice0();
      this.GET_ChoosePrice1();
    },
    orderinvoiceListChange (e) {
      this.selectOrderinvoice = e;
      this.handleSelectionChange(e);
    },
    handleSelectionChange (val) {
      console.log(val)
      this.thisPage = val.length === this.orderInvoiceList.length;
      let ids = [];
      val.forEach((item) => {
        ids.push(item.order_id);
      });
      this.idsList = ids;
      this.GET_ToDoPrice0();
      this.GET_ChoosePrice1();
    },
    GET_ToDoPrice0 () {
      // 待开订单表总金额
      API_goods.getWaitOrderTotalPrice({
        enterprise_invoice: 0,
        endTime: this.paramsData.endTime,
        startTime: this.paramsData.startTime,
        seller_name: this.paramsData.seller_name,
        shop_id: this.paramsData.shop_id,
      }).then((res) => {
        this.toDoPrice0 = res;
      });
    },
    GET_ChoosePrice1 () {
      // 待开订单表总金额
      if (this.idsList.length > 0) {
        API_goods.getWaitOrderTotalPrice({
          enterprise_invoice: 0,
          endTime: this.paramsData.endTime,
          startTime: this.paramsData.startTime,
          order_ids: this.idsList,
          seller_name: this.paramsData.seller_name,
          shop_id: this.paramsData.shop_id,
        }).then((res) => {
          this.choosePrice1 = res;
        });
      } else {
        this.choosePrice1 = 0;
      }
    },
    isChangeThisPage () {
      // this.thisPage = !this.thisPage;
      this.allPage = false;
      if (this.thisPage) {
        this.$refs.orderInvoiceList.toggleAllSelection();
      } else {
        this.$refs.orderInvoiceList.clearSelection();
      }
    },
    isChangeAllPage () {
      this.thisPage = false;
      // this.allPage = true;
      this.$refs.orderInvoiceList.clearSelection();
    },

    // 服务发票
    getShopReceipt () {
      let params = { ...this.paramsData }
      params.end_time = params.endTime
      params.start_time = params.startTime
      delete params.startTime
      delete params.endTime
      API_goods.getShopReceipt(params).then((res) => {
        this.serviceInvoiceList = res.data;
        this.tableData = res;
      });
      this.GET_ServiceToDoPrice0();
      this.GET_ServiceChoosePrice1();
    },
    serviceInvoiceListChange (e) {
      this.selectServiceInvoice = e;
      this.handleSelectionChangeService(e);
    },
    handleSelectionChangeService (val) {
      this.thisPageService = val.length === this.serviceInvoiceList.length;
      let ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });
      // this.choosePrice1 = val.reduce(( counter ,item )=>counter+ item.order_price,0)
      this.idsListService = ids;
      this.GET_ServiceToDoPrice0();
      this.GET_ServiceChoosePrice1();
    },
    GET_ServiceToDoPrice0 () {
      API_goods.getWaitServiceOrderTotalPrice({
        enterprise_invoice: 0,
        endTime: this.paramsData.endTime,
        startTime: this.paramsData.startTime,
        seller_name: this.paramsData.seller_name,
        shop_id: this.paramsData.shop_id,
      }).then((res) => {
        this.toDoPrice0 = res;
      });
    },
    GET_ServiceChoosePrice1 () {
      // 待开订单表总金额
      if (this.idsListService.length > 0) {
        API_goods.getWaitServiceOrderTotalPrice({
          enterprise_invoice: 0,
          endTime: this.paramsData.endTime,
          startTime: this.paramsData.startTime,
          ids: this.idsListService,
          seller_name: this.paramsData.seller_name,
          shop_id: this.paramsData.shop_id,
        }).then((res) => {
          this.choosePrice1 = res;
        });
      } else {
        this.choosePrice1 = 0;
      }
    },
    isChangeThisPageService () {
      // this.thisPageService = true;
      this.allPageService = false;
      if (this.thisPageService) {
        this.$refs.serviceInvoiceList.toggleAllSelection();
      } else {
        this.$refs.serviceInvoiceList.clearSelection();
      }
    },
    isChangeAllPageService () {
      this.thisPageService = false;
      // this.allPageService = true;
      this.$refs.serviceInvoiceList.clearSelection();
    },

    // 运费发票
    getPlatFormFreight () {
      API_goods.getPlatFormFreight(this.paramsData).then(response => {
        console.log(response)
        this.platFormInvoiceList = response.data;
        this.tableData = response;
      })
      this.getPlatFormFreightTotalPrice()
      this.getChoosePlatFormFreightTotalPrice()
    },
    platFormInvoiceListChange (e) {
      this.selectPlatFormInvoiceList = e;
      this.handleSelectionChangePlatForm(e);
    },
    handleSelectionChangePlatForm (val) {
      this.currentPagePlatForm = val.length === this.platFormInvoiceList.length;
      let ids = [];
      val.forEach((item) => {
        ids.push(item.trade_id);
      });
      this.idsListPlatForm = ids;
      this.getPlatFormFreightTotalPrice();
      this.getChoosePlatFormFreightTotalPrice();
    },
    isChangeThisPagePlatForm () {
      // this.currentPagePlatForm = true;
      this.allPagePlatForm = false;
      if (this.currentPagePlatForm) {
        this.$refs.platFormInvoiceList.toggleAllSelection();
      } else {
        this.$refs.platFormInvoiceList.clearSelection();
      }
    },
    isChangeAllPagePlatForm () {
      this.currentPagePlatForm = false;
      // this.allPagePlatForm = true;
      this.$refs.platFormInvoiceList.clearSelection();
    },
    getPlatFormFreightTotalPrice () {
      let params = {
        platform_invoice: 0,
        endTime: this.paramsData.endTime,
        startTime: this.paramsData.startTime,
        seller_name: this.paramsData.seller_name,
        shop_id: this.paramsData.shop_id,
      }
      API_goods.getPlatFormFreightTotalPrice(params).then(resp => {
        this.toDoPrice0 = resp;
      })
    },
    getChoosePlatFormFreightTotalPrice () {
      const _params = {
        platform_invoice: 0,
        endTime: this.paramsData.endTime,
        startTime: this.paramsData.startTime,
        trade_ids: this.idsListPlatForm,
        seller_name: this.paramsData.seller_name,
        shop_id: this.paramsData.shop_id,
      }
      // 待开订单表总金额
      if (this.idsListPlatForm.length > 0) {
        API_goods.getPlatFormFreightTotalPrice(_params).then((res) => {
          this.choosePrice1 = res;
        });
      } else {
        this.choosePrice1 = 0;
      }
    },

    // 跳转方法
    requestInvoice () {
      // 索取发票->跳转
      let arr = [],
        str = "",
        is = "", // is===1 订单发票     is===2   服务发票
        price = 0;
      let data = {
        is_all: null,
        order_ids: [],
      };

      if (this.activeName === "orderInvoiceList") {
        is = "1";
        price = this.choosePrice1
        this.selectOrderinvoice.map((item) => {
          arr.push(item.order_id);
        });

        str = arr.join(",");
        if (this.thisPage) {
          data.is_all = 0;
          data.order_ids = arr;
        } else if (this.allPage) {
          data.is_all = 1;
          // delete data.order_ids;
          data.order_ids = ["0"];
          price = this.toDoPrice0;
        } else {
          if (arr.length <= 0) {
            this.$message.error("请先选择开票订单");
            return;
          } else {
            data.is_all = 0;
            data.order_ids = arr;
          }
        }
      }
      if (this.activeName === "serviceInvoiceList") {
        is = "2";
        this.selectServiceInvoice.map((item) => {
          arr.push(item.id);
          price += item.price;
        });
        str = arr.join(",");
        if (this.thisPageService) {
          data.is_all = 0;
          data.order_ids = arr;
        } else if (this.allPageService) {
          data.is_all = 1;
          // delete data.order_ids;
          data.order_ids = ["0"];
          price = this.toDoPrice0;
        } else {
          if (arr.length <= 0) {
            this.$message.error("请先选择开票订单");
            return;
          } else {
            data.is_all = 0;
            data.order_ids = arr;
          }
        }
      }
      if (this.activeName === "platFormFreightList") {
        is = "3";
        this.selectPlatFormInvoiceList.map((item) => {
          arr.push(item.trade_id);
          price += item.platform_freight_price;
        });
        str = arr.join(",");
        if (this.currentPagePlatForm) {
          data.is_all = 0;
          data.order_ids = arr;
        } else if (this.allPagePlatForm) {
          data.is_all = 1;
          // delete data.order_ids;
          data.order_ids = ["0"];
          price = this.toDoPrice0;
        } else {
          if (arr.length <= 0) {
            this.$message.error("请先选择开票订单");
            return;
          } else {
            data.is_all = 0;
            data.order_ids = arr;
          }
        }
      }
      {
        if (this.activeName === "orderInvoiceList") {
          API_goods.getWaitReceiptOrderListaddHistory({
            step: 1,
            order_ids: data.order_ids,
            is_all: data.is_all,
            startTime: this.paramsData.startTime,
            endTime: this.paramsData.endTime,
            shop_id: this.paramsData.shop_id,
            invoice_check: 1
          }).then((res) => {
            this.paramsData.step = 2;
            this.$router.push({
              path: "Invoicemanger/requestInvoice",
              query: { id: str, is, price },
            });
          });
        }
        if (this.activeName === "serviceInvoiceList") {
          API_goods.getShopReceiptaddHistoryService({
            step: 1,
            ids: data.order_ids,
            is_all: data.is_all,
            startTime: this.paramsData.startTime,
            endTime: this.paramsData.endTime,
            invoice_check: 1
          }).then((res) => {
            this.paramsData.step = 2;
            this.$router.push({
              path: "Invoicemanger/requestInvoice",
              query: { id: str, is, price },
            });
          });
        }
        if (this.activeName === "platFormFreightList") {
          const _params = {
            step: 1,
            trade_ids: data.order_ids,
            is_all: data.is_all,
            startTime: this.paramsData.startTime,
            endTime: this.paramsData.endTime,
            shop_id: this.paramsData.shop_id,
            invoice_check: 1
          }
          API_goods.addHistoryFreight(_params).then(res => {
            this.paramsData.step = 2;
            this.$router.push({
              path: "Invoicemanger/requestInvoice",
              query: { id: str, is, price },
            });
          })
        }
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.paramsData.page_size = size;
      if (this.activeName === "orderInvoiceList") {
        this.getWaitReceiptOrderList();
      } else {
        this.getShopReceipt();
      }
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.paramsData.page_no = page;
      if (this.activeName === "orderInvoiceList") {
        this.getWaitReceiptOrderList();
      } else {
        this.getShopReceipt();
      }
    },
    // 查询方法
    getList () {
      this.paramsData.page_no = 1;
      if (this.value1 && this.value1.length && this.value1.length > 0) {
        this.paramsData.startTime = Math.floor(this.value1[0] / 1000);
        this.paramsData.endTime = Math.floor(this.value1[1] / 1000 + 86399);
      } else {
        this.paramsData.startTime = "";
        this.paramsData.endTime = "";
      }
      this.handleClick();
    },
    // 查看开票记录
    showPartFn (val) {
      if (val === 1) {
        this.$router.push({ path: "/finance/Invoicemanger/Invoicemangerdetail" });
      } else {
        this.$router.push({
          path: "/finance/Invoicemanger/Invoicemangerjilu",
          query: {
            is: this.activeName === "orderInvoiceList" ? "1" : this.activeName === "serviceInvoiceList" ? "2" : "3",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ div.toolbar {
  height: auto;
  border: none;
}

.detal1 {
  padding: 0 !important;
  background: #f5f6f9 !important;
}

/deep/ .el-tabs__header {
  margin: 0 !important;
  border: none !important;

  .el-tabs__nav,
  .el-tabs__item,
  .el-tabs__header {
    border: none !important;
  }

  .el-tabs__item:not(.is-active) {
    color: #999;
  }

  .is-active {
    background: #fff;
    border-radius: 5px 5px 0 0;
  }

  .el-tabs__item {
    color: #000;
  }
}

.stepbox span {
  font-size: 18px;
  margin-right: 10px;
}

.stepbox {
  box-sizing: border-box;
  padding: 0px 15px;
  min-width: 900px;
  width: 100%;
  /* height: 90px; */
  background: #fff;
  border-radius: 0 5px 0 0;
  padding-top: 50px;

  >div {
    display: inline-block;
    width: 380px;
    height: 48px;
    text-align: center;

    p {
      width: 340px;
      height: 48px;
      background: #1a43a9;
      line-height: 48px;
      margin: 0;
      text-align: center;
      color: #ffffff;
      float: left;
    }

    div {
      float: left;
      width: 0;
      height: 0;
      border-left: 14px solid #1a43a9;
      border-top: 24px solid #ffffff;
      border-bottom: 24px solid #ffffff;
    }

    .threeths {
      border-left: 14px solid #ffffff;
      border-top: 24px solid #1a43a9;
      border-bottom: 24px solid #1a43a9;
    }
  }

  .activediv {
    p {
      background: #c0c4cc;
    }

    .threeths {
      border-top: 24px solid #c0c4cc;
      border-bottom: 24px solid #c0c4cc;
      border-left: 14px solid #ffffff;
    }

    div {
      float: left;
      border-left: 14px solid #c0c4cc;
      border-top: 24px solid #ffffff;
      border-bottom: 24px solid #ffffff;
    }
  }
}

.seletboxp {
  background: #f5f6f9;
  padding: 20px 0 5px 0;

  >p {
    padding: 0;
    position: relative;
    padding-left: 18px;
    font-size: 16px;
    line-height: 22px;
    color: #393c41;
  }

  >p:before {
    content: " ";
    display: block;
    width: 6px;
    height: 22px;
    background: #1a43a9;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
  }
}

.seletbox {
  width: 100%;
  min-width: 900px;
  height: 250px;
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  padding-top: 30px;
  border-radius: 0 0 5px 5px;

  p {
    line-height: 25px;
    margin: 0;
  }

  >div {
    display: inline-block;
    margin-top: 20px;
  }

  .hover {
    >span {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
    }

    span:hover {
      color: #1a43a9;
    }
  }
}

.databox2 {
  padding-top: 0px !important;
}

.databox {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 5px !important;

  >p {
    padding: 0;
    position: relative;
    padding-left: 18px;
    font-size: 16px;
    line-height: 22px;
    color: #393c41;
  }

  >div {
    background: #ffffff;
    border-radius: 5px;
    width: 100%;

    div {
      display: inline-block;

      p {
        margin: 0;
        width: 100%;
        padding-left: 5%;
        text-align: left;
        line-height: 24px;
        border-right: 1px solid #dcdfe6;
      }
    }

    .goright {
      display: inline-block;
      padding-left: 4%;
      width: 19%;
    }
  }

  .yikai {
    color: #e3405b;
    font-size: 30px;
    padding-top: 10px;
    font-weight: bold;
  }

  .daikai {
    color: #5dbe2d;
    font-size: 30px;
    padding-top: 10px;
    font-weight: bold;
  }

  .noueb {
    border: none;
    margin-left: -7%;
  }
}

.invoiceTitle {
  font-size: 18px;
  font-weight: 400;
  color: #666;
}

.invoiceInformationBox {
  .invoiceInformationContent {
    margin-top: 10px;
    margin-left: 18%;
    border: 1px solid #000;

    .invoiceInformationType {
      margin-left: 20px;

      span {
        font-size: 14px;
        color: #1abc9c;
      }
    }

    .invoiceInformationMain {
      display: flex;

      span {
        display: block;
        line-height: 40px;
        font-size: 16px;
      }

      .left {
        width: 140px;
        margin-right: 15px;

        span {
          text-align: right;
        }
      }

      .right {
        span {
          text-align: left;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.detal {
  line-height: 35px;
  background: #fff;
  /* padding: 20px; */
  border-radius: 0 0 5px 5px;
}

.span {
  display: inline-block;
  width: 150px;
  text-align: right;
}

/* .detal h3 {
  padding: 0;
  position: relative;
  padding-left: 18px;
  font-size: 16px;
  line-height: 22px;
  color: #393c41;
  font-weight: normal;
}
.detal h3:before {
  content: " ";
  display: block;
  width: 6px;
  height: 22px;
  background: #1a43a9;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
} */
.detal .h3 {
  display: block;
  height: 10px;
  background: #f5f6f9;
}

.toolbar-search {
  width: 100% !important;
}

/deep/ .el-table--border th {
  border-right: 1px solid rgba(220, 223, 230, 1);
}

/deep/ .el-table--border td {
  border-right: 1px solid rgba(220, 223, 230, 1);
}

/deep/ .el-table--border td {
  border-bottom: 1px solid rgba(220, 223, 230, 1);
}

/deep/ .el-table td:not(.is-left) {
  text-align: center;
}
</style>
